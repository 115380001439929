import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import ModalDadosGerais from './informacoes-gerais/index';
import axios from 'axios';
import HttpStatus from 'http-status-codes';
import { getHeaders } from '../../request';
import { ValidationDadosGerais } from './informacoes-gerais/validationSchema';
import { ValidationDadosExame } from './dados-exame/validationSchema';
import { SttAlerta, SttLoading, SttTranslateHook } from '@stt-componentes/core';
import { CONSTANTES_ENVIO_EXAME_CLASSIFICAR_DOR_TORACICA, CONSTANTES_INVESTIGACAO_DOR_DOR_MOMENTO_EXAME, MODALIDADE, PERFIL } from '../../common/Constants';
import { temPerfilRBAC } from '../../security/acl';
import Utils from '../../utils';

const initialValues = {
    "equipamento": null,
    "observacao": "",
    "validarImagem": false,
    'dorMomentoExame': CONSTANTES_INVESTIGACAO_DOR_DOR_MOMENTO_EXAME.NAO,
    'classificarDorToracica': CONSTANTES_ENVIO_EXAME_CLASSIFICAR_DOR_TORACICA.NAO,
    'intensidadeDor': null,
    'localizacaoDor': [],
    'tipoDor': [],
    'outroTipoDor': '',
    'caracterizacaoDorToracica': [],
    "anexo": [{}]
};

const EnvioImagens = ({ open, resetFormulario, solicitacao = {}, user, handleCancelarEnvio, callbackFinalizarEnvio, voltar }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const ELETRO_API_BASE_URL = global.gConfig.url_base_eletro;

    const steps = [{ titulo: strings.dadosGerais }, { titulo: strings.dadosExame }, { titulo: strings.anexos }];
    const [stepAtual, setStepAtual] = useState(0);

    // Controle do id do exame enviado para geração do protocolo
    const [exameEnviado, setExameEnviado] = useState(null);

    //Esquemas de validação
    const schemaInformacoesGerais = ValidationDadosGerais(strings, user);
    const schemaDadosExame = ValidationDadosExame(strings, user);

    const schemaGeral = schemaInformacoesGerais.concat(schemaDadosExame);

    //Anexos
    const [anexos, setAnexos] = useState([]);

    //Alerta 
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [mostrarAlertaCancelamento, setMostrarAlertaCancelamento] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState(strings.tituloModalCancelamento);
    const [mensagemAlerta, setMensagemAlerta] = useState(strings.mensagemAlertaCancelamento);
    const [tipoAlerta, setTipoAlerta] = useState('alert');

    // Notificação
    const [progresso, setProgresso] = useState(false);
    const [mensagemProgresso, setMensagemProgresso] = useState(strings.mensagemEnviandoImagens);

    // Impressão do protocolo
    const [gerarProtocolo, setGerarProtocolo] = useState(false);

    // Impressão do TCLE
    const [gerarTermo, setGerarTermo] = useState(false);
    const [paciente, setPaciente] = useState(null);

    // Controle de equipamento
    const [equipamentos, setEquipamentos] = useState([]);
    const [erroEquipamento, setErroEquipamento] = useState(false);

    useEffect(() => {
        let instituicoes = [];
        if (!temPerfilRBAC(user, PERFIL.MEDICO_SOLICITANTE)) {
            instituicoes = user.habilitacao.solicitacaoServico.filter(inst => inst.modalidades?.some(modalidade => modalidade.sigla === MODALIDADE.SIGLA)).map(inst => inst.id);
        } else {
            instituicoes = user.habilitacao.vinculo.map(inst => inst.id);
        }

        if (instituicoes.length) {
            const stringInsts = instituicoes.join();
            const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;
            axios
                .get(`${UTILITARIOS_API_BASE_URL}/equipamento?modalidade=${MODALIDADE.SIGLA}&instituicao=${stringInsts}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        setEquipamentos(response.data);
                    } else {
                        setEquipamentos([]);
                        setErroEquipamento(true);
                    }
                })
                .catch(err => console.log(err));
        }

    }, [user]);

    useEffect(() => {
        if (erroEquipamento) {
            setTituloAlerta(strings.erro);
            setMensagemAlerta(strings.noaExisteEquipamento);
            setTipoAlerta('error');
            setOpcoesAlerta([
                {
                    title: strings.ok,
                    onClick: () => {
                        setMostrarAlerta(false);
                        cancelarEnvio();
                    }
                }
            ]);
            setMostrarAlerta(true);
        }

    }, [erroEquipamento]);

    const cancelarEnvio = () => {
        if (handleCancelarEnvio) {
            handleCancelarEnvio();
        }
        resetFormulario();
    }

    /**
     * Mostra ao usuário alerta para encerramento do envio de imagens
     */
    const confirmarFecharModal = () => {
        setMostrarAlertaCancelamento(true);
    }

    const [opcoesAlerta, setOpcoesAlerta] = useState([]);

    /**
     * Reseta o formulário de envio de imagens e da solicitação
     */
    const finalizarEnvioImagens = () => {
        if (callbackFinalizarEnvio) {
            callbackFinalizarEnvio();
        }
        resetFormulario();
    }

    /**
     * Gera o protocolo do exame
     *
     */
    const imprimirProtocolo = (idExame) => {
        Utils.imprimirProtocoloFn({ id: idExame }, () => {});
    }

    /**
     * Gera o termo de autorização
     * 
     * @param {number} paciente
     */
    const imprimirTermo = (pac) => {
        Utils.imprimirTcleFn({ id: pac }, () => {
            setMostrarAlerta(false);
            finalizarEnvioImagens();
        });
    }

    useEffect(() => {
        if (exameEnviado) {
            setMensagemProgresso(strings.gerandoNumeroProtocolo);
            imprimirProtocolo(exameEnviado);
        }
    }, [gerarProtocolo]);

    useEffect(() => {
        if (paciente) {
            imprimirTermo(paciente);
        }
    }, [gerarTermo]);

    const submitForm = (data, setSubmitting, resetForm) => {
        if(progresso) return;
        
        setProgresso(true);
        let dados = { ...data };

        const formData = new FormData();
        formData.append('id_funcionario', user.idFuncionario);
        formData.append('id_solicitacao', solicitacao.id);
        formData.append('id_equipamento', dados['equipamento'].id);
        if (dados['observacao']) {
            formData.append('observacao', dados['observacao']);
        }
        formData.append('dor_momento_exame', dados['dorMomentoExame']);

        if (dados['dorMomentoExame'] === 'S') {
            formData.append('classificar_dor_toracica', dados['classificarDorToracica']);
        }

        if (dados['classificarDorToracica'] === 'S') {
            formData.append('intensidade_dor', dados['intensidadeDor'].id);
            formData.append('localizacao_dor', dados['localizacaoDor'].map(l => l.id));
            formData.append('caracterizacao_dor_toracica', dados['caracterizacaoDorToracica'].map(c => c.id));

            const tipoDorFormatado = dados['tipoDor'].map(t => {
                return {
                    id: t.id,
                    necessita_complemento: t.necessita_complemento,
                    complemento: t.necessita_complemento ? dados['outroTipoDor'].trim() : null
                }
            });
            formData.append('tipo_dor', JSON.stringify(tipoDorFormatado));
        }

        dados.anexo.forEach((anexo, index) => {
            if (anexo && (anexo instanceof File)) {
                formData.append(`nome_anexos.${index}`, anexo.name);
                formData.append(`anexo.${index}`, anexo);
            }
        });

        axios
            .post(`${ELETRO_API_BASE_URL}/exame`, formData, { headers: { ...getHeaders(), 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if (response.status == 201) {
                    // Armazena o identificador do exame gerado
                    setExameEnviado(response.data.data.exame);
                    setPaciente(response.data.data.paciente);
                    // Gera o protocolo
                    setGerarProtocolo(true);
                    // Gera o TCLE
                    setGerarTermo(true);
                } else {
                    setProgresso(false);
                    setTituloAlerta(strings.titulo);
                    setMensagemAlerta(strings.mensagemErro);
                    setTipoAlerta('error');
                    setOpcoesAlerta([
                        {
                            title: strings.ok,
                            onClick: () => { setMostrarAlerta(false) }
                        }
                    ]);
                    setMostrarAlerta(true);
                }
            })
            .catch(err => {
                setProgresso(false);
                const { response } = err;
                let msg = strings.mensagemErroGeral;
                let titulo = '';
                let msgAlerta = '';

                let onClickError = () => { setMostrarAlerta(false) };

                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const dadosResp = response.data;
                        let arrMensagem = [];
                        dadosResp.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                        titulo = dadosResp.message;
                        msgAlerta = msg;
                    } else if (response.status === HttpStatus.CONFLICT) {
                        const dadosResp = response.data;
                        let arrMensagem = [];
                        dadosResp.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                        titulo = strings.exameDuplicado;
                        msgAlerta = msg;
                        onClickError = () => { voltar() }
                    }else {
                        titulo = strings.erro;
                        msgAlerta = msg;
                    }
                } else {
                    titulo = strings.erro;
                    msgAlerta = msg;
                }

                // Notificação
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: onClickError
                    }
                ]);
                setTipoAlerta('error');
                setTituloAlerta(titulo);
                setMensagemAlerta(msgAlerta);
                setMostrarAlerta(true);
            })
            .finally(() => {
                setSubmitting(false);
            });
    }
    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={schemaGeral}
            onSubmit={(data, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                const params = {
                    idSolicitacao: solicitacao.id,
                    idEquipamento: data['equipamento'].id
                }

                // TODO: realizar essa verificação no próprio endpoint de envio do exame
                axios
                    .get(`${ELETRO_API_BASE_URL}/verificar-exame-repetido`, { params, headers: getHeaders() })
                    .then((response) => {
                        if (response.status == 200) {
                            if (response.data.exameRepetido) {
                                let titulo = strings.exameDuplicado;
                                let msgAlerta = strings.mensagemExameDuplicado;
                                setOpcoesAlerta([
                                    {
                                        title: strings.sim,
                                        onClick: () => { submitForm(data, setSubmitting, resetForm); }
                                    },
                                    {
                                        title: strings.nao,
                                        onClick: () => { setMostrarAlerta(false) }
                                    }
                                ]);
                                setTipoAlerta('alert');
                                setTituloAlerta(titulo);
                                setMensagemAlerta(msgAlerta);
                                setMostrarAlerta(true);
                            } else {
                                submitForm(data, setSubmitting, resetForm);
                            }
                        }
                    })
                    .catch(err => {
                        setSubmitting(false);
                    });;

            }}
        >
            {
                ({
                    touched,
                    validateForm,
                    setTouched,
                    values,
                    setFieldValue,
                    handleSubmit
                }) => {
                    return (
                        <>
                            <form noValidate onSubmit={handleSubmit}>
                                {
                                    <ModalDadosGerais
                                        anexos={anexos}
                                        setAnexos={setAnexos}
                                        open={open}
                                        steps={steps}
                                        stepAtual={stepAtual}
                                        setStepAtual={setStepAtual}
                                        callbackFinalizado={handleSubmit}
                                        titulo={strings.titulo}
                                        confirmarFecharModal={confirmarFecharModal}
                                        validateForm={validateForm}
                                        touched={touched}
                                        setTouched={setTouched}
                                        values={values}
                                        solicitacao={solicitacao}
                                        setFieldValue={setFieldValue}
                                        equipamentos={equipamentos}
                                    />
                                }

                                <SttLoading
                                    open={progresso}
                                    text={mensagemProgresso}
                                />

                                <SttAlerta
                                    open={mostrarAlerta}
                                    title={tituloAlerta}
                                    message={mensagemAlerta}
                                    type={tipoAlerta}
                                    options={opcoesAlerta}
                                    onClose={() => {
                                        setMostrarAlerta(false);
                                    }}
                                />

                                <SttAlerta
                                    open={mostrarAlertaCancelamento}
                                    title={strings.tituloModalCancelamento}
                                    message={strings.mensagemAlertaCancelamento}
                                    type={'alert'}
                                    onClose={() => {
                                        setMostrarAlertaCancelamento(false);
                                    }}
                                    options={
                                        [
                                            {
                                                title: strings.sim,
                                                onClick: () => {
                                                    setMostrarAlertaCancelamento(false);
                                                    cancelarEnvio();
                                                }
                                            },

                                            {
                                                title: strings.nao,
                                                onClick: () => {
                                                    setMostrarAlertaCancelamento(false);
                                                }
                                            }
                                        ]
                                    }
                                />
                            </form>
                        </>
                    )
                }
            }
        </Formik>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps, null)(EnvioImagens);